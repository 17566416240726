import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';

const useCollapse = (
  {
    collapsibleContainer,
    defaultCollapseStatus = true,
  }: {
    collapsibleContainer: HTMLElement | null;
    defaultCollapseStatus?: boolean;
  },
  deps: DependencyList = []
) => {
  const [collapseHeight, setCollapseHeight] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultCollapseStatus);

  useEffect(() => {
    if (!collapsibleContainer) return;
    setCollapseHeight(collapsibleContainer.scrollHeight);
  }, [collapsibleContainer, ...deps]);

  const triggerCollapsing = useCallback((open?: boolean) => setIsCollapsed((prev) => open ?? !prev), []);
  const height = useMemo(() => {
    return isCollapsed ? 0 : collapseHeight;
  }, [isCollapsed, collapseHeight]);

  return { height, triggerCollapsing, isCollapsed };
};

export default useCollapse;
