import React, { useMemo } from 'react';
import FullPage from '@/src/components/UI/FullPage';
import OpenGraph from '@/components/OpenGraph';
import { useAsync } from 'react-use';
import FunwooAPI, { BuildingShowcaseEntity, BuildingStateTag } from '@/swagger';
import FunwooImage from '@/components/common/FunwooImage';
import Flexbox from '@/components/common/Flexbox';
import { CountryEnum } from '@/constants/country';
import { dollarLabelFormatter } from '@/src/libs/number-helper';
import { Button } from '@chakra-ui/react';
import classNames from 'classnames';
import Link from 'next/link';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { locationData } from '@/lib/constant';
import { capitalize } from 'lodash';
import { isNotSet } from '@/utils/formatChecker';
import { isBefore } from 'date-fns';

interface Props {
  showcases: BuildingShowcaseEntity[];
}

const Building: React.FC<Props> = ({ showcases }) => {
  return (
    <React.Fragment>
      <FullPage title={'全新建案'} hasPadding hasBottomMargin={false} hasHeaderPlaceHolder>
        <OpenGraph
          title='FUNWOO 德載國際不動產 - 全新建案'
          type='funwoo.building'
          url='https://funwoo.com.tw/building'
        />
        <p className={'hidden invisible sm:block sm:visible py-4 text-2xl text-center font-medium'}>全新建案</p>
        <p className={'px-6 pt-3 pb-4 sm:py-0 font-noto text-lg font-medium text-gold text-center'}>
          <span>欲了解其它新建案， 請撥打</span>
          <br className={'sm:hidden'} />
          <a className={'underline'} href={'tel:02-7752-3755'}>
            02-7752-3755
          </a>
        </p>
        <div className={'p-4 sm:flex sm:flex-wrap sm:-mr-6'}>
          {showcases?.map((showcase) => (
            <Showcase
              key={showcase.id}
              className={classNames(
                'mb-8 last:mb-0 sm:last:mb-8 sm:mr-6',
                'w-full',
                'sm:w-[calc(50%-24px)]',
                'md:w-[calc((100%/3)-24px)]',
                'sm:flex-shrink-0'
              )}
              {...showcase}
            />
          ))}
        </div>
      </FullPage>
    </React.Fragment>
  );
};

export default Building;

export const getServerSideProps: GetServerSideProps<Props> = async ({ locale = '' }) => {
  const { data } = await FunwooAPI.buildingApi.getBuildingShowcases();
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      showcases: data,
    },
  };
};

const Showcase: React.FC<BuildingShowcaseEntity & { className?: string }> = ({
  sid,
  buildingImage,
  adTitle,
  country,
  state,
  city,
  minimumPrice,
  buildingFloorPlan,
  buildingState,
  completeYear,
  completeMonth,
  className,
}) => {
  const { value: _locationData } = useAsync(async () => {
    return locationData;
  }, []);

  const cityName = useMemo(() => {
    if (state) {
      const stateData = _locationData?.[country]?.find((res) => res.key === state);
      return stateData?.cities?.find((res) => res.key === city)?.zh_tw;
    } else {
      return city;
    }
  }, [_locationData, state, city]);

  const startPrice = useMemo(() => {
    if (minimumPrice % 10000 === 0) {
      return minimumPrice / 10000;
    } else {
      return (minimumPrice / 10000).toFixed(1);
    }
  }, [minimumPrice]);

  const _buildingState = useMemo(() => {
    const today = new Date();
    const completeDate = new Date(completeYear, completeMonth, 0);

    if (isBefore(today, completeDate)) {
      return buildingState.concat({
        state: BuildingStateTag.PRESALE,
        id: 'PRESALE',
        expiredAt: new Date(completeYear, completeMonth, 0).toISOString(),
        buildingId: 'PRESALE',
        createdAt: new Date().toISOString(),
      });
    } else {
      return buildingState;
    }
  }, [buildingState, completeYear, completeMonth]);

  const displayImage = useMemo(
    () => buildingImage.filter((image) => isNotSet(image.tag))?.[0]?.watermark ?? '',
    [buildingImage]
  );

  return (
    <Link href={`/building/${sid}`} passHref>
      <a
        target={'_blank'}
        rel={'noreferrer noopener'}
        className={classNames(
          className,
          'group',
          'block',
          'rounded',
          'shadow-itemCard hoverable:hover:shadow-itemCardFocus transition-[shadow,transform] duration-300',
          'overflow-hidden'
        )}
      >
        <FunwooImage
          useDefaultPlaceholder
          src={displayImage}
          height={2}
          width={3}
          className={'w-full'}
          imageClassName={'group-hover:group-hover:scale-105 transition-transform duration-300'}
          layout={'fill'}
          alt={adTitle}
        >
          <StateLabels state={_buildingState} />
        </FunwooImage>
        <div className={'px-3 pt-2 pb-4'}>
          <Flexbox as={'p'} align={'center'} className={'text-sm text-gold'}>
            <span>{CountryEnum[country]}</span>
            <span className={'mx-1'}>｜</span>
            <span>{cityName}</span>
          </Flexbox>
          <p className={'mb-2 pb-1 text-lg font-medium border-b border-gray300'}>{adTitle}</p>
          <p className={'mb-4 text-base text-gray700'}>
            ${startPrice}萬{dollarLabelFormatter(country, '台幣')}起
          </p>
          <p className={'mb-4 font-noto text-base text-gray700'}>
            {buildingFloorPlan.map((floorPlan) => capitalize(floorPlan.planType)).join('、')}
          </p>
          <Button w={'full'}>瞭解更多</Button>
        </div>
      </a>
    </Link>
  );
};

const StateWording: Record<BuildingStateTag, string> = {
  [BuildingStateTag.PRESALE]: '預售屋',
  [BuildingStateTag.NEW]: '新建案',
  [BuildingStateTag.EXCLUSIVE]: 'FUNWOO獨家',
  [BuildingStateTag.OPEN_HOUSE]: 'Open House',
};

const StateLabels: React.FC<{ state: BuildingShowcaseEntity['buildingState'] }> = ({ state }) => {
  return (
    <div className={'absolute top-2 left-2'}>
      {state.map((state) => (
        <span
          key={state.id}
          className={classNames('mr-1 last:mr-0', 'py-1 px-2', 'text-xs font-bold', {
            'bg-white text-gold': state.state !== BuildingStateTag.PRESALE,
            'bg-brand text-white': state.state === BuildingStateTag.PRESALE,
          })}
        >
          {StateWording[state.state]}
        </span>
      ))}
    </div>
  );
};

export const BuildingShowcaseCard = Showcase;
